<template>
  <g>
    <g>
      <line :x1="canvas.thickness + radius"
            :x2="canvas.thickness + radius"
            :y1="canvas.thickness - (canvas.thickness / 3) + 25"
            :y2="canvas.thickness * 2 + 25"
            stroke="red"
      />
      <text ref="midi1">{{ $t('calendar.canvas.midday') }}</text>
    </g>

    <g>
      <line :x1="canvas.thickness + (radius * 3)"
            :x2="canvas.thickness + (radius * 3)"
            :y1="canvas.thickness + (radius * 2) + 25"
            :y2="canvas.thickness * 2 + (radius * 2) + (canvas.thickness / 3) + 25"
            stroke="red"
      />
      <text ref="minuit">{{ $t('calendar.canvas.midnight') }}</text>
    </g>

    <g>
      <line :x1="canvas.thickness + (radius * 5)"
            :x2="canvas.thickness + (radius * 5)"
            :y1="canvas.thickness - (canvas.thickness / 3) + 25"
            :y2="canvas.thickness * 2 + 25"
            stroke="red"
      />
      <text ref="midi2">{{ $t('calendar.canvas.midday') }}</text>
    </g>
  </g>
</template>

<script>
export default {
  name: 'CalendarSvgPeriods',

  props: {
    canvas: {
      required: true,
      type: Object,
    },
    radius: {
      required: true,
      type: Number,
    },
  },

  mounted () {
    this.canvas.element = this.$refs.container__svg
    const midi1 = this.$refs.midi1
    midi1.setAttribute(
        'x',
        `${this.canvas.thickness + this.radius - (midi1.getBBox().width / 2)}`,
    )
    midi1.setAttribute(
        'y',
        `${this.canvas.thickness - (this.canvas.thickness / 3) +20}`,
    )

    const midi2 = this.$refs.midi2
    midi2.setAttribute(
        'x',
        `${this.canvas.thickness + (this.radius * 5) - (midi2.getBBox().width / 2)}`,
    )
    midi2.setAttribute(
        'y',
        `${this.canvas.thickness - (this.canvas.thickness / 3) +20}`,
    )

    const minuit = this.$refs.minuit
    minuit.setAttribute(
        'x',
        `${this.canvas.thickness + (this.radius * 3) - (minuit.getBBox().width / 2)}`,
    )
    minuit.setAttribute(
        'y',
        `${(this.canvas.thickness * 2) + (this.radius * 2)  + (this.canvas.thickness) + 20}`,
    )
  },
}
</script>

<style lang="scss" scoped>
g {
  text {
    font-size : 20px;
    user-select: none;
  }
}
</style>
