<template>
  <svg viewBox="0 0 1280.000000 1187.000000">
    <g transform="translate(0.000000,1187.000000) scale(0.100000,-0.100000)"
       :fill="color">
      <path d="M4646 11838 l-34 -34 -6 -274 c-3 -151 -8 -760 -10 -1354 -6 -1170
-7 -1125 48 -1162 18 -11 38 -15 64 -11 22 2 178 77 368 175 l329 169 385 8
c289 5 393 10 418 21 38 16 67 72 57 112 -7 30 -1447 2317 -1479 2350 -14 14
-40 26 -64 29 -37 5 -45 2 -76 -29z"/>
      <path d="M9617 11256 c-36 -15 -2082 -1892 -2096 -1922 -23 -51 -2 -113 47
-137 15 -8 188 -56 386 -108 l358 -94 272 -243 c149 -134 286 -250 305 -258
43 -19 84 -10 114 25 25 30 30 47 486 1656 287 1015 271 956 271 993 0 63 -82
114 -143 88z"/>
      <path d="M708 9044 c-31 -17 -58 -61 -58 -97 0 -13 10 -38 23 -56 17 -24 1145
-1084 1925 -1808 128 -119 134 -123 177 -123 81 0 83 4 203 391 l107 346 272
262 c225 216 273 267 279 295 7 41 -10 82 -46 107 -15 11 -368 101 -895 229
-478 116 -962 234 -1075 261 -640 156 -862 209 -873 209 -7 -1 -24 -7 -39 -16z"/>
      <path d="M6225 8834 c-291 -23 -446 -47 -669 -104 -549 -140 -1054 -427 -1457
-830 -238 -237 -401 -464 -544 -755 -206 -419 -295 -833 -282 -1300 8 -278 53
-535 135 -775 333 -973 1189 -1717 2244 -1954 797 -179 1621 -70 2316 306 820
443 1372 1187 1521 2048 34 196 44 340 38 549 -8 289 -47 514 -133 770 -202
600 -621 1141 -1169 1506 -421 280 -915 458 -1445 519 -134 16 -459 27 -555
20z"/>
      <path d="M9783 7605 c-40 -17 -63 -51 -63 -96 0 -29 36 -96 187 -350 l188
-313 7 -330 c3 -182 7 -346 7 -365 1 -24 11 -43 36 -68 31 -31 40 -35 74 -30
25 3 464 235 1287 680 1352 731 1302 701 1290 777 -3 21 -17 45 -35 61 l-29
27 -263 6 c-145 3 -802 7 -1460 10 -955 3 -1202 1 -1226 -9z"/>
      <path d="M2458 5766 c-57 -30 -623 -336 -1258 -679 -1250 -677 -1208 -651
-1196 -727 3 -21 17 -45 35 -61 l29 -27 263 -6 c145 -3 802 -7 1460 -10 1279
-4 1237 -5 1273 45 37 54 28 77 -171 410 l-188 313 -7 330 c-3 182 -7 346 -7
365 -1 45 -56 101 -99 101 -18 0 -75 -23 -134 -54z"/>
      <path d="M9955 4887 c-22 -19 -45 -80 -135 -371 -81 -262 -114 -355 -132 -371
-13 -12 -134 -128 -270 -259 -209 -200 -248 -243 -254 -273 -8 -43 13 -93 47
-111 24 -12 2808 -692 2836 -692 27 0 73 31 88 60 19 38 19 58 -2 96 -20 34
-2038 1926 -2067 1937 -36 14 -85 7 -111 -16z"/>
      <path d="M3831 3377 c-13 -6 -32 -23 -43 -37 -10 -14 -94 -293 -187 -620 -93
-327 -257 -906 -365 -1286 -108 -380 -196 -708 -196 -728 0 -28 7 -45 29 -66
36 -37 69 -45 109 -28 25 10 1854 1676 2053 1869 27 26 50 59 53 77 9 36 -13
91 -44 110 -11 7 -183 55 -382 108 -200 53 -368 99 -374 102 -7 4 -129 112
-273 241 -143 129 -269 239 -278 246 -29 20 -76 26 -102 12z"/>
      <path d="M8060 2863 c-8 -3 -161 -81 -340 -173 l-325 -167 -385 -8 c-289 -5
-393 -10 -418 -21 -38 -16 -67 -72 -57 -112 8 -30 1447 -2317 1479 -2350 14
-14 40 -26 64 -29 37 -5 45 -2 76 29 l34 34 6 304 c3 168 8 777 10 1354 6
1128 7 1093 -45 1130 -23 16 -69 20 -99 9z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'CalendarSvgSun',

  props: {
    color: {
      required: false,
      type: String,
      default: 'black'
    }
  }
}
</script>
