<template>
  <section class="settings__container">
    <div v-if="hasLumino">
      <h2>{{ $t('calendar.container.lumino.title') }}</h2>

      <card-slider-settings v-for="lumino in luminoList"
                            :key="lumino.id"
                            :item="lumino"
                            :read-only="readOnly"
                            class="lumino"
                            type="lumino"
                            @valid="onDeleteSlider"
      >
        <template v-slot:delete-icon>
          <calendar-svg-bulb minus :size="40"/>
        </template>

        <template v-slot:card-body>
          <p>
            {{ $t('calendar.container.lumino.session.from') }}
            <v-btn @click="onDisplayPickers({id: lumino.id, ...lumino.start}, 'start')" :disabled="readOnly">
              {{ displayDateTime(lumino.start) | humanDate }} - {{ lumino.start.hh }}
            </v-btn>
            {{ $t('calendar.container.lumino.session.to') }}
            <v-btn @click="onDisplayPickers({id: lumino.id, ...lumino.end}, 'end')" :disabled="readOnly">
              {{ displayDateTime(lumino.end) | humanDate }} - {{ lumino.end.hh }}
            </v-btn>
          </p>
        </template>
      </card-slider-settings>
    </div>

    <div v-if="hasNap">
      <h2>{{ $t('calendar.container.nap.title') }}</h2>

      <card-slider-settings v-for="nap in napsList"
                            :key="nap.id"
                            :item="nap"
                            class="nap"
                            type="nap"
                            @valid="deleteSlider"
      >
        <template v-slot:delete-icon>
          <calendar-svg-nap minus :size="40"/>
        </template>

        <template v-slot:card-body>
          <p>
            {{ $t('calendar.container.nap.session.from') }}
            <v-btn @click="onDisplayPickers({id: nap.id, ...nap.start}, 'start')" :disabled="readOnly">
              {{ displayDateTime(nap.start) | humanDate }} - {{ nap.start.hh }}
            </v-btn>
            {{ $t('calendar.container.nap.session.to') }}
            <v-btn @click="onDisplayPickers({id: nap.id, ...nap.end}, 'end')" :disabled="readOnly">
              {{ displayDateTime(nap.end) | humanDate }} - {{ nap.end.hh }}
            </v-btn>
          </p>
        </template>
      </card-slider-settings>
    </div>

    <date-hour-picker :display.sync="dialog.display"
                      :date.sync="dialog.values.date"
                      :hour.sync="dialog.values.hour"
                      @cancel="onHiddenPickerDialog"
                      @valid="onValidDateHour"
    />
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CardSliderSettings from '@/components/Calendar/CardSliderSettings'
import CalendarSvgBulb from '@/components/Calendar/Icon/Bulb'
import DateHourPicker from '@/components/Calendar/DateHourPicker'
import PickersMixin from '@/components/Calendar/PickersMixin'
import CalendarSvgNap from '@/components/Calendar/Icon/Nap'

export default {
  name: 'CalendarSliderSimpleContainer',

  components: {
    CalendarSvgNap,
    DateHourPicker,
    CalendarSvgBulb,
    CardSliderSettings,
  },

  mixins: [PickersMixin],

  computed: {
    ...mapGetters([
      'slidersList',
    ]),
    hasLumino () {
      return this.luminoList.length
    },
    hasNap () {
      return this.napsList.length
    },
    luminoList () {
      return this.slidersList.filter(slider => slider.type === 'lumino')
    },
    napsList () {
      return this.slidersList.filter(slider => slider.type === 'nap')
    },
  },

  methods: {
    ...mapActions([
      'deleteSlider',
    ]),
    onDeleteSlider (sliderId) {
      this.deleteSlider(sliderId)
      this.$emit('update-value', { value: null })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "container";

.settings__container {
  max-width: 100%;

  .nap, .lumino {
    .v-card__text {
      button {
        font-size: 15px;

        @media all and (min-width: $m) {
          font-size: 18px;
        }
      }
    }
  }
}
</style>
