<template>
  <svg viewBox="0 0 50 50"
       :width="size"
       :height="size"
       :x="x"
       :y="y"
       :fill="color"
  >
    <path d="M0,42.5 v-30 h3 v18 h21 v5 h-21 v7z"/>
    <path d="M26,22.5 h17 a7,7 0 0 1 7,7 v13 h-3 v-7 h-21 v-13z"/>
    <circle r="4" cx="9" cy="24.5" :fill="color"/>
    <path d="M24,28.5 h-9 a6,6 0 0 1 6,-6 h3z"/>
    <text x="9" y="19.5" transform="rotate(-10, 9, 19.5)" font-size="14" v-if="withZ">Z</text>
    <text x="14" y="14.5" transform="rotate(5, 14, 14.5)" font-size="11" v-if="withZ">Z</text>
    <text x="20" y="10.5" transform="rotate(-5, 20, 10.5)" font-size="8" v-if="withZ">Z</text>

    <g v-if="plus">
      <circle r="10" cx="40" cy="40" fill="white"/>
      <path d="M34.5,38.5 h4 v-4 h3 v4 h4 v3 h-4 v4 h-3 v-4 h-4 v-3z"/>
    </g>
    <g v-if="minus">
      <circle r="10" cx="40" cy="40" fill="white"/>
      <path d="M34.5,38 h11 v4 h-11 v-4z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'CalendarSvgSleep',

  props: {
    color: {
      required: false,
      type: String,
      default: 'black',
    },
    minus: {
      required: false,
      type: Boolean,
      default: false,
    },
    plus: {
      required: false,
      type: Boolean,
      default: false,
    },
    size: {
      required: false,
      type: Number,
      default: 20,
    },
    withZ: {
      required: false,
      type: Boolean,
      default: false,
    },
    x: {
      required: false,
      type: Number,
      default: 0,
    },
    y: {
      required: false,
      type: Number,
      default: 0,
    },
  },
}
</script>
