<template>
  <svg viewBox="0 0 9 19">
    <path d="M3,0 h3 v10 h3 l-4.5,9 l-4.5,-9 h3 v10" class="arrow start"/>
  </svg>
</template>

<script>
export default {
  name: 'CalendarSvgCursorArrowStart',
}
</script>

<style lang="scss" scoped>
@import "cursorsSlideStyle";
</style>
