<template>
  <svg viewBox="0 0 27 50"
       @mousedown="$emit('mousedown', $event)"
       @touchstart="$emit('mousedown', $event)"
       @mouseup="$emit('mouseup', $event)"
       @touchend="$emit('mouseup', $event)"
  >
    <calendar-svg-eye-opened width="27" height="20" x="0" y="0"/>

    <path d="M12,25 h3 v20 h-3 v-20" class="cursor end"
          @mousedown="$emit('mousedown')"
          @touchstart="$emit('mousedown')"
    />
  </svg>
</template>

<script>

import CalendarSvgEyeOpened from '@/components/Calendar/Icon/EyeOpened'

export default {
  name: 'CalendarSvgCursorEyeOpened',

  components: { CalendarSvgEyeOpened },
}
</script>

<style lang="scss" scoped>
@import "cursorsSlideStyle";
</style>
