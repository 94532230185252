<template>
  <section id="day-information__container">
    <calendar-slider-complex-container v-if="hasComplexSliders" @update-value="onUpdate" :read-only="readOnly"/>
    <calendar-capsule-settings-container v-if="hasMedicine" @update-value="onUpdate" :read-only="readOnly"/>
    <calendar-slider-simple-container v-if="hasSimpleSliders" @update-value="onUpdate" :read-only="readOnly"/>

    <p v-if="!hasComplexSliders && !hasMedicine && !hasSimpleSliders">{{ $t('calendar.container.noData') }}</p>
  </section>
</template>

<script>
import CalendarCapsuleSettingsContainer from '@/components/Calendar/CapsuleSettingsContainer'
import { mapGetters } from 'vuex'
import CalendarSliderSimpleContainer from '@/components/Calendar/SliderSimpleContainer'
import CalendarSliderComplexContainer from '@/components/Calendar/SliderComplexContainer'

export default {
  name: 'DayInformationContainer',

  components: {
    CalendarSliderComplexContainer,
    CalendarSliderSimpleContainer,
    CalendarCapsuleSettingsContainer,
  },

  props: {
    readOnly: {
      required: false,
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters([
      'capsulesList',
      'slidersList',
    ]),
    hasMedicine () {
      return this.capsulesList.length
    },
    hasSimpleSliders () {
      return this.slidersList.filter(slider => slider.isSimple).length
    },
    hasComplexSliders () {
      return this.slidersList.filter(slider => !slider.isSimple).length
    },
  },

  methods: {
    onUpdate (event) {
      this.$emit('update-value', event)
    },
  },
}
</script>

<style lang="scss" scoped>
@import "src/assets/style/style";

#day-information__container {
  background-color: $whiteTransparent;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 20px;

  > p {
    font-size: 25px;
    font-style: italic;
    text-align: center;
  }
}
</style>
