<template>
  <svg viewBox="0 0 50 50"
       :width="size"
       :height="size"
       :x="x"
       :y="y"
       :fill="color"
  >
    <path id="body"
          d="M19.5,22.9c-1.4,2.7-2.8,5.3-4.3,8c0.2,0,0.4,0,0.6,0c1.4,0,2.8,0,4.2,0c1.5,0,2.5,1,2.5,2.5c0,4.2,0,8.4,0,12.6c0,1.4-1,2.5-2.4,2.5s-2.4-1.1-2.4-2.5c0-3.2,0-6.4,0-9.7c0-0.2,0-0.4,0-0.6c-0.2,0-0.3,0-0.5,0c-2.5,0-4.9,0-7.4,0c-2.3,0-4.2-1.4-4.8-3.6c-0.1-1.1,0-2.2,0.6-3.3c2-3.8,4-7.6,6.1-11.4c0.9-1.7,2.4-2.5,4.4-2.5c1.9,0.1,3.3,1,4.2,2.8c0.1,0.2,0.3,0.4,0.5,0.4c1.2,0.5,2.5,1,3.7,1.5c0.2,0.1,0.5,0.1,0.7,0.1c1.5,0,3.1,0,4.6,0c1.4,0,2.5,1,2.5,2.4s-1.1,2.4-2.6,2.4c-1.7,0-3.5,0-5.2,0c-0.4,0-0.9-0.1-1.3-0.2C21.9,23.9,20.8,23.4,19.5,22.9z"
    />
    <path id="table"
          d="M24.2,29.4H21c0,0-1.5,0-1.5-1.6c0-1.5,1.7-1.5,1.7-1.5h26.9c0,0,1.6,0,1.6,1.5c0,1.6-1.5,1.6-1.5,1.6H45v17.4c0,0,0,1.7-1.6,1.7s-1.6-1.7-1.6-1.7V29.4H27.4v17.4c0,0,0.1,1.7-1.6,1.7c-1.7,0-1.6-1.7-1.6-1.7L24.2,29.4z"
    />
    <path id="chair"
          d="M3.5,37.3h9.4c0,0,1.7,0.1,1.7,1.6s-1.7,1.5-1.7,1.5H8.3v3.9c0,0.4,0.3,0.5,0.5,0.7l2.2,1.8c0,0,0.4,0.6,0.1,1.1c-0.4,0.5-1.1,0.2-1.1,0.2l-1.6-1.3c0,0,0,1.7-0.9,1.7s-0.8-1.7-0.8-1.7l-1.8,1.4c0,0-0.6,0.3-1-0.2s0-1.1,0-1.1L6.4,45c0.2-0.2,0.4-0.4,0.4-0.7v-3.9H2c-0.9-0.1-1.4-0.6-1.6-1.6v-24c0,0,0-1.5,1.6-1.5s1.5,1.5,1.5,1.5V37.3z"
    />
    <text transform="matrix(0.9848 -0.1736 0.1736 0.9848 32.1603 16.9126)"
          class="st0 st1"
    >
      Z
    </text>
    <text transform="matrix(0.9962 8.715574e-02 -8.715574e-02 0.9962 37.1604 11.9127)"
          class="st0 st2">
      Z
    </text>
    <text transform="matrix(0.9962 -8.715574e-02 8.715574e-02 0.9962 43.1604 7.9128)"
          class="st0 st3">
      Z
    </text>
    <circle id="head" cx="26" cy="14.5" r="4.2"/>

    <g v-if="plus">
      <circle r="10" cx="40" cy="40" fill="white"/>
      <path d="M34.5,38.5 h4 v-4 h3 v4 h4 v3 h-4 v4 h-3 v-4 h-4 v-3z"/>
    </g>
    <g v-if="minus">
      <circle r="10" cx="40" cy="40" fill="white"/>
      <path d="M34.5,38 h11 v4 h-11 v-4z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'CalendarSvgNap',

  props: {
    color: {
      required: false,
      type: String,
      default: 'black',
    },
    minus: {
      required: false,
      type: Boolean,
      default: false,
    },
    plus: {
      required: false,
      type: Boolean,
      default: false,
    },
    size: {
      required: false,
      type: Number,
      default: 20,
    },
    x: {
      required: false,
      type: Number,
      default: 0,
    },
    y: {
      required: false,
      type: Number,
      default: 0,
    },
  },
}
</script>

<style lang="scss" scoped>
svg {
  .st0 {font-family: "ArialMT", sans-serif;}

  .st1 {font-size: 14px;}

  .st2 {font-size: 11px;}

  .st3 {font-size: 8px;}
}
</style>
