<template>
  <svg viewBox="0 0 500 50"
       ref="svg_container"
       @mousemove="onMouseMove"
       @touchmove="onMouseMove"
       @mouseup="onMouseUp"
       @touchend="onMouseUp"
       :class="{ read_only: readOnly }"
  >
    <pattern id="diagonalHatch" patternUnits="userSpaceOnUse" width="10" height="10" class="hatches">
      <path d="M0,0 h10 v10 h-10 v-10" fill="white"/>
      <path d="M0,0 h1 l-1,1 v-1"/>
      <path d="M10,10 h-1 l1,-1 v1"/>
      <path d="M0,9 l9,-9 h1 v1 l-9,9 h-1 v-1"/>
    </pattern>

    <g class="cursors">

      <g class="cursors__sleep">
        <g v-for="(sleep, index) in slider.sleeps" :key="sleep.id">
          <path :d="sleepPath(sleep)" fill="blue"/>
          <path :d="`M13.5,30 h${sleep.start.x} v10 h-${sleep.start.x} v-10`"
                fill="url(#diagonalHatch)"
                v-if="index === 0"
          />
          <path
              :d="`M${sleep.end.x + 13.5},30 H${slider.sleeps[index + 1].start.x + 13.5} v10 H${sleep.end.x + 13.5} v-10`"
              fill="url(#diagonalHatch)"
              v-if="index !== (slider.sleeps.length - 1)"
          />
          <calendar-svg-cursor-arrow-start width="9"
                                           height="19"
                                           x="9"
                                           y="30"
                                           v-if="index === 0"
          />
          <path :d="`M${sleep.end.x + 13.5},30 H486.5 v10 H${sleep.end.x + 13.5} v-10`"
                fill="url(#diagonalHatch)"
                v-if="index === (slider.sleeps.length - 1)"
          />
          <calendar-svg-cursor-arrow-end width="9"
                                         height="19"
                                         x="482"
                                         y="21"
                                         v-if="index === (slider.sleeps.length - 1)"
          />
          <calendar-svg-cursor-eye-closed width="27"
                                          height="50"
                                          y="0"
                                          :x="sleep.start.x"
                                          :ref="`cursor__start-${sleep.id}`"
                                          @mousedown="onMouseDown('start',sleep.id)"
                                          @touchstart="onMouseDown('start', sleep.id)"
                                          @mouseup="onMouseUp"
                                          @touchend="onMouseUp"
          />
          <calendar-svg-cursor-eye-opened width="27"
                                          height="50"
                                          y="0"
                                          :x="sleep.end.x"
                                          :ref="`cursor__end-${sleep.id}`"
                                          @mousedown="onMouseDown('end',sleep.id)"
                                          @touchstart="onMouseDown('end', sleep.id)"
                                          @mouseup="onMouseUp"
                                          @touchend="onMouseUp"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CalendarSvgCursorArrowStart from '@/components/Calendar/Icon/CursorArrowStart'
import CalendarSvgCursorArrowEnd from '@/components/Calendar/Icon/CursorArrowEnd'
import { stepCalculate } from '@/store/modules/Agenda/Sliders'
import CalendarSvgCursorEyeOpened from '@/components/Calendar/Icon/CursorEyeOpened'
import CalendarSvgCursorEyeClosed from '@/components/Calendar/Icon/CursorEyeClosed'

export default {
  name: 'SliderSettings',

  components: {
    CalendarSvgCursorEyeClosed,
    CalendarSvgCursorEyeOpened,
    CalendarSvgCursorArrowEnd,
    CalendarSvgCursorArrowStart,
  },

  props: {
    readOnly: {
      required: false,
      type: Boolean,
      default: false,
    },
    slider: {
      require: true,
    },
  },

  data: () => ({
    canvas: {
      rect: null,
      width: null,
    },
    cursors: {
      selected: {
        sleepId: null,
        element: null,
        type: '',
      },
    },
    hourStep: 15,
  }),

  computed: {
    ...mapGetters([
      'user',
    ]),
    diff () {
      return (this.endHour.getTime() - this.startHour.getTime()) / 1000
    },
    endHour () {
      return new Date(`${this.slider.end.hd}T${this.slider.end.hh}`)
    },
    maxStep () {
      return 473 / this.step
    },
    startHour () {
      return new Date(`${this.slider.start.hd}T${this.slider.start.hh}`)
    },
    step () {
      if (this.diff === 0) return 0

      return (this.hourStep * 60) / (this.diff / 473)
    },
    today () {
      let today = null
      if (this.user && this.$route.params.day) {
        today = new Date(this.user.inclusionDate)
        today.setDate(today.getDate() + parseInt(this.$route.params.day))
      } else {
        today = new Date()
      }

      return today
    },
  },

  mounted () {
    this.hourStep = process.env.VUE_APP_CALENDAR_STEP_TIME
    this.canvas.rect = this.$refs['svg_container'].getBoundingClientRect()
    this.canvas.width = this.$refs['svg_container'].clientWidth
  },

  methods: {
    ...mapActions([
      'sleepHourChange',
      'updateCursorSlider',
    ]),
    sleepPath (sleep) {
      const startX = this.sleepCalculateCoordinate('start', sleep) + 13.5
      const endX = this.sleepCalculateCoordinate('end', sleep) + 13.5
      const diff = endX - startX
      return `M${startX},29 h${diff} v12 h-${diff} v-12z`
    },
    sleepCalculateCoordinate (type, awakening) {
      const x = this.sleepCalculateStep(type, awakening) * this.step
      this.updateCursorSlider({
        sliderId: this.slider.id,
        sleepId: awakening.id,
        cursorName: type,
        field: 'x',
        value: x,
      })
      return x
    },
    sleepCalculateStep (type, awakening) {
      const { h: sliderH, d: sliderD } = this.slider.start
      const { h, d } = awakening[type]

      const step = stepCalculate(
          h,
          d,
          sliderH,
          sliderD,
          this.hourStep,
          this.maxStep,
      )
      this.updateCursorSlider({
        sliderId: this.slider.id,
        sleepId: awakening.id,
        cursorName: type,
        field: 'stepNumber',
        value: step,
      })
      return step
    },
    /**
     * Selects the good cursor when user click on the cursor
     *
     * @param {String} type
     * @param {String} sleepId
     */
    onMouseDown (type, sleepId) {
      this.cursors.selected.element = this.$refs[`cursor__${type}-${sleepId}`]
      this.cursors.selected.sleepId = sleepId
      this.cursors.selected.type = type
    },
    /**
     * Calculate the x step and the x coordinate when user move his mouse.
     * 13.5 is the half of the eye cursor width -> the start of the chart.
     * 486.5 = 500 - 13.5
     *
     * @param {MouseEvent} event
     */
    onMouseMove (event) {
      if (this.cursors.selected.element && !this.readOnly) {
        let stepNumber = (event.clientX - this.canvas.rect.left) * 500 / this.canvas.width - 13.5
        stepNumber = Math.round(stepNumber / this.step)
        const x = stepNumber * this.step

        const sleepUpdatedIndex = this.slider.sleeps.findIndex(sleep => sleep.id === this.cursors.selected.sleepId)
        const sleepUpdated = this.slider.sleeps[sleepUpdatedIndex]
        const oldStepNumber = sleepUpdated[this.cursors.selected.type].stepNumber

        if (stepNumber > 0 && stepNumber < this.maxStep && stepNumber !== oldStepNumber) {
          this.updateCursorSlider({
            sliderId: this.slider.id,
            sleepId: this.cursors.selected.sleepId,
            cursorName: this.cursors.selected.type,
            field: 'stepNumber',
            value: stepNumber,
          })
          this.updateCursorSlider({
            sliderId: this.slider.id,
            sleepId: this.cursors.selected.sleepId,
            cursorName: this.cursors.selected.type,
            field: 'x',
            value: x,
          })
          let minutes = stepNumber * this.hourStep
          const hours = (minutes - (minutes % 60)) / 60
          minutes -= hours * 60

          this.sleepHourChange({
            type: this.cursors.selected.type,
            minutes: Math.round(minutes),
            hours: Math.round(hours),
            id: this.slider.id,
            sleepId: this.cursors.selected.sleepId,
            today: this.today,
            checkOthers: true,
            isIncrease: stepNumber > oldStepNumber
          })
        }
      }
    },
    /**
     * Deselects the cursor when user up the mouse button
     */
    onMouseUp () {
      this.cursors.selected.element = null
      this.cursors.selected.isSleep = false
      this.cursors.selected.sleepId = null
      this.cursors.selected.type = ''
      this.$emit('update-value', { value: null })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "Icon/cursorsSlideStyle";
</style>
