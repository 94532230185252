<template>
  <g :class="{ 'read-only': readOnly }">
    <g class="capsule"
       v-for="capsule in capsulesList"
       :key="capsule.id"
       :ref="capsule.id"
       :id="capsule.id"
       @mousedown="onMouseDown"
       @touchstart="onMouseDown"
    >
      <calendar-svg-capsule :x="capsuleSizes.x"
                            :y="capsuleSizes.y"
                            :size="capsules.defaultSize"
                            :main-color="capsule.color"
      />
      <path :d="capsuleCursor" :fill="capsule.color"/>
    </g>
  </g>
</template>

<script>
import CalendarSvgCapsule from '@/components/Calendar/Icon/Capsule'
import { mapGetters } from 'vuex'

export default {
  name: 'CalendarCapsuleCursor',

  components: { CalendarSvgCapsule },

  props: {
    canvas: {
      required: true,
      type: Object,
    },
    radius: {
      required: true,
      type: Number,
    },
    readOnly: {
      required: true,
      type: Boolean,
      default: false,
    },
    zonesSelectable: {
      required: true,
      type: Object,
    },
  },

  data: () => ({
    capsules: {
      defaultColor: '#cd0500',
      defaultSize: 15,
    },
  }),

  computed: {
    ...mapGetters([
      'capsulesList',
    ]),
    capsuleCursor () {
      const cursorHeight = this.canvas.backgroundSize + (this.radius / 10)
      const cursorWith = 6
      let cursorPositionInitial = {}
      cursorPositionInitial.x = this.canvas.thickness - (cursorHeight / 2)
      cursorPositionInitial.y = this.canvas.height / 2 - (cursorWith / 2)

      return `M${cursorPositionInitial.x},${cursorPositionInitial.y} h${cursorHeight} a3,3 0 0 1 0,6 h-${cursorHeight} a3,3 0 0 1 0,-6z`
    },
    capsuleSizes () {
      return {
        x: this.canvas.thickness + (this.radius / 5),
        y: (this.canvas.height / 2) - (this.capsules.defaultSize / 2),
      }
    },
  },

  watch: {
    capsulesList: {
      deep: true,
      handler () {
        this.capsulesList.forEach(capsule => {
          this.capsuleAngleCalculate(capsule)
        })
      },
    },
    radius () {
      this.capsulesList.forEach(capsule => {
        this.capsuleAngleCalculate(capsule)
      })
    },
  },

  mounted () {
    this.capsulesList.forEach(capsule => {
      this.capsuleAngleCalculate(capsule)
    })
  },

  methods: {
    capsuleAngleCalculate (capsule) {
      let zoneSelected = {
        translate: '',
        cx: null,
        cy: null,
      }
      let hour = 0
      let angle = 0
      let angleHour = 360 / 24

      if (capsule.d === -1) {
        hour = 24 - capsule.h

        if (hour <= 18 && hour > 6) {
          zoneSelected = this.zonesSelectable.a
          angle = (18 - hour) * angleHour
        } else if (hour <= 6 && hour >= 0) {
          zoneSelected = this.zonesSelectable.b
          angle = -((6 - hour) * angleHour)
        }
      } else if (capsule.d === 1) {
        hour = capsule.h

        if (hour >= 0 && hour < 6) {
          zoneSelected = this.zonesSelectable.b
          angle = 270 - (hour * angleHour)
        } else if (hour >= 6 && hour <= 18) {
          zoneSelected = this.zonesSelectable.c
          angle = (hour - 6) * angleHour
        }
      } else {
        zoneSelected = this.zonesSelectable.b
        angle = -90
      }

      this.$nextTick(() => {
        if (this.$refs[capsule.id]) {
          this.$refs[capsule.id][0].setAttribute(
              'transform',
              `${zoneSelected.translate} rotate(${angle}, ${this.canvas.transformOrigin.cx}, ${this.canvas.transformOrigin.cy})`,
          )
        }
      })
    },
    onMouseDown (event) {
      if (!this.readOnly) {
        this.$emit('cursor-selected', null)

        const components = event.path || (event.composedPath && event.composedPath())

        components.forEach(element => {
          if (element.classList && element.classList.contains('capsule')) {
            this.$emit('cursor-selected', element)
            this.$emit('mouse-downed', true)
          }
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
g:not(.read-only) {
  .capsule {
    cursor: pointer;
    z-index: 1000;
  }
}
</style>
