<template>
  <section class="settings-complex__container">
    <h2>{{ $t('calendar.container.sleep.title') }}</h2>

    <card-slider-settings v-for="slider in complexList"
                          :key="slider.id"
                          :item="slider"
                          :read-only="readOnly"
                          class="sleep"
                          type="sleep"
                          @valid="onDeleteSlider"
    >
      <template v-slot:delete-icon>
        <calendar-svg-sleep minus :size="40"/>
      </template>

      <template v-slot:card-body>
        <calendar-slider-settings :slider="slider" @update-value="$emit('update-value', $event)" :read-only="readOnly"/>

        <aside>
          <div>
            <div class="pickers__options pickers__start">
              <calendar-svg-cursor-arrow-start width="18" height="38" x="0" y="30"/>
              <label>{{ $t('calendar.container.sleep.labels.start') }}</label>
              <v-btn @click="onDisplayPickers({id: slider.id, ...slider.start}, 'start')" :disabled="readOnly">
                {{ slider.start.hd | humanDate }} {{ $t('calendar.container.sleep.labels.at') }} {{ slider.start.hh }}
              </v-btn>
            </div>
            <div class="pickers__options pickers__end">
              <calendar-svg-cursor-arrow-end width="18" height="38" x="0" y="30"/>
              <label>{{ $t('calendar.container.sleep.labels.end') }}</label>
              <v-btn @click="onDisplayPickers({id: slider.id, ...slider.end}, 'end')" :disabled="readOnly">
                {{ slider.end.hd | humanDate }} {{ $t('calendar.container.sleep.labels.at') }} {{ slider.end.hh }}
              </v-btn>
            </div>

            <div class="pickers__options pickers__sleep">
              <calendar-svg-cursor-eye-closed width="25" height="45" x="0" y="30"/>
              <label>{{ $t('calendar.container.sleep.labels.sleep') }}</label>
              <v-btn @click="onDisplayPickers({id: slider.sleeps[0].id, ...slider.sleeps[0].start}, 'start', true)"
                     :disabled="readOnly">
                {{ slider.sleeps[0].start.hd | humanDate }} {{ $t('calendar.container.sleep.labels.at') }}
                {{ slider.sleeps[0].start.hh }}
              </v-btn>
            </div>
            <div class="pickers__options pickers__awakening">
              <calendar-svg-cursor-eye-opened width="25" height="45" x="0" y="30"/>
              <label>{{ $t('calendar.container.sleep.labels.awakening') }}</label>
              <v-btn @click="onDisplayPickers({id: slider.sleeps[0].id, ...slider.sleeps[0].end}, 'end', true)"
                     :disabled="readOnly">
                {{ slider.sleeps[0].end.hd | humanDate }} {{ $t('calendar.container.sleep.labels.at') }}
                {{ slider.sleeps[0].end.hh }}
              </v-btn>
            </div>

            <div class="pickers__actions">
              <v-btn fab @click="onAddAwakening(slider.id)" :disabled="readOnly">
                <calendar-svg-sleep plus :size="40" with-z/>
              </v-btn>
            </div>
          </div>

          <div v-for="awakening in slider.sleeps.slice(1)" :key="awakening.id">
            <div>
              <v-btn @click="onDisplayPickers({id: awakening.id, ...awakening.start}, 'start', true)"
                     :disabled="readOnly">
                <calendar-svg-cursor-eye-closed width="30.5" height="45" x="0" y="30"/>
                {{ awakening.start.hd | humanDate }} - {{ awakening.start.hh }}
              </v-btn>
            </div>
            <div>
              <v-btn @click="onDisplayPickers({id: awakening.id, ...awakening.end}, 'end', true)"
                     :disabled="readOnly">
                <calendar-svg-cursor-eye-opened width="30.5" height="45" x="0" y="30"/>
                {{ awakening.end.hd | humanDate }} - {{ awakening.end.hh }}
              </v-btn>
            </div>

            <div>
              <v-btn fab @click="onDeleteAwakening({sliderId: slider.id, id: awakening.id})"
                     :disabled="readOnly">
                <calendar-svg-sleep minus :size="40" with-z/>
              </v-btn>
            </div>
          </div>
        </aside>
      </template>
    </card-slider-settings>

    <date-hour-picker :display.sync="dialog.display"
                      :date.sync="dialog.values.date"
                      :hour.sync="dialog.values.hour"
                      :options="dialog"
                      is-sleep-picker
                      @cancel="onHiddenPickerDialog"
                      @valid="onValidDateHour"
    />
  </section>
</template>

<script>
import CalendarSliderSettings from '@/components/Calendar/SliderSettings'
import { mapActions, mapGetters } from 'vuex'
import CalendarSvgCursorArrowStart from '@/components/Calendar/Icon/CursorArrowStart'
import CalendarSvgCursorArrowEnd from '@/components/Calendar/Icon/CursorArrowEnd'
import DateHourPicker from '@/components/Calendar/DateHourPicker'
import PickersMixin from '@/components/Calendar/PickersMixin'
import CalendarSvgSleep from '@/components/Calendar/Icon/Sleep'
import CardSliderSettings from '@/components/Calendar/CardSliderSettings'
import CalendarSvgCursorEyeClosed from '@/components/Calendar/Icon/CursorEyeClosed'
import CalendarSvgCursorEyeOpened from '@/components/Calendar/Icon/CursorEyeOpened'

export default {
  name: 'CalendarSliderComplexContainer',

  mixins: [PickersMixin],

  components: {
    CalendarSvgCursorEyeOpened,
    CalendarSvgCursorEyeClosed,
    CardSliderSettings,
    CalendarSvgSleep,
    DateHourPicker,
    CalendarSvgCursorArrowEnd,
    CalendarSvgCursorArrowStart,
    CalendarSliderSettings,
  },

  computed: {
    ...mapGetters([
      'slidersList',
    ]),
    complexList () {
      return this.slidersList.filter(slider => !slider.isSimple)
    },
  },

  methods: {
    ...mapActions([
      'addSleep',
      'deleteAwakening',
      'deleteSlider',
    ]),
    onAddAwakening (sliderId) {
      this.addSleep(sliderId)
      this.$emit('update-value', { value: null })
    },
    onDeleteAwakening (payload) {
      this.deleteAwakening(payload)
      this.$emit('update-value', { value: null })
    },
    onDeleteSlider (sliderId) {
      this.deleteSlider(sliderId)
      this.$emit('update-value', { value: null })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "src/assets/style/style";
@import "container";

.settings-complex__container {
  max-width: 100%;

  .v-card {
    margin-bottom: 20px;

    aside {
      > div {
        display: flex;
        flex-direction: column;
        margin: 15px 0;

        @media all and (min-width: $m) {
          display: grid;
          grid-template-columns: repeat(2, 1fr) 100px;
          grid-template-areas: "start end actions" "sleep awakening actions";
          grid-gap: 20px;
        }

        .pickers__start {
          grid-area: start;
        }

        .pickers__end {
          grid-area: end;
        }

        .pickers__sleep {
          grid-area: sleep;
        }

        .pickers__awakening {
          grid-area: awakening;
        }

        .pickers__actions {
          grid-area: actions;
          margin-top: 20px;

          @media all and (min-width: $m) {
            margin-top: 0;
          }
        }

        .pickers__options {
          margin-top: 20px;
          display: grid;
          grid-template-areas: "icon label" "icon button";

          @media all and (min-width: $m) {
            margin-top: 0;
          }

          svg {
            grid-area: icon;
            margin-right: 20px;
          }

          label {
            font-size: 20px;
            grid-area: label;

            @media all and (min-width: $m) {
              font-size: 25px;
            }
          }

          button {
            grid-area: button;
            font-size: 14px;

            &.v-btn--disabled {
              color: rgba(0, 0, 0, 0.8) !important;
            }

            @media all and (min-width: $m) {
              font-size: 18px;
            }
          }
        }

        > div {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &:not(:first-child) {
          > div {
            button {
              margin-top: 15px;
              font-size: 14px;
              padding: 25px;

              &.v-btn--disabled {
                color: rgba(0, 0, 0, 0.8) !important;
              }

              @media all and (min-width: $m) {
                font-size: 18px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
