<template>
  <div class="settings__container" v-if="displaySection">
    <h2>{{ $t('calendar.container.medicine.title') }}</h2>

    <section>
      <card-slider-settings v-for="capsule in capsulesList"
                            :key="capsule.id"
                            :item="capsule"
                            :read-only="readOnly"
                            class="capsule"
                            type="medicine"
                            @valid="onDeleteCursor"
      >
        <template v-slot:delete-icon>
          <calendar-svg-capsule minus :main-color="capsule.color"/>
        </template>

        <template v-slot:card-body>
          <v-select :items="medicines"
                    :value="capsule.isOther ? 'other' : capsule.medicine"
                    @change="onChangeSelectValue($event, capsule.id)"
                    :disabled="readOnly"
          />
          <v-text-field v-if="capsule.isOther"
                        :value="capsule.medicine"
                        @input="onUpdateMedicine($event, capsule.id)"
          />

          <v-btn @click="onDisplayPickers(capsule)" :disabled="readOnly">
            {{ displayDateTime(capsule) | humanDate }} - {{ capsule.hh }}
          </v-btn>
        </template>
      </card-slider-settings>
    </section>

    <!-- Dialog to update hour and date with pickers -->
    <date-hour-picker :display.sync="dialog.display"
                      :hour.sync="dialog.values.hour"
                      :date.sync="dialog.values.date"
                      @cancel="onHiddenPickerDialog"
                      @valid="onValidDateHour"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CalendarSvgCapsule from '@/components/Calendar/Icon/Capsule'
import { getHumanDate } from '@/store/modules/Agenda'
import DateHourPicker from '@/components/Calendar/DateHourPicker'
import CardSliderSettings from '@/components/Calendar/CardSliderSettings'
import PickersMixin from '@/components/Calendar/PickersMixin'

export default {
  name: 'CalendarCapsuleSettingsContainer',

  components: {
    CardSliderSettings,
    DateHourPicker,
    CalendarSvgCapsule,
  },

  mixins: [PickersMixin],

  data: () => ({
    card: {
      display: false,
      capsuleId: '',
    },
  }),

  computed: {
    ...mapGetters([
      'capsulesList',
      'medicines',
      'survey',
      'surveyResults',
    ]),
    displaySection () {
      return this.capsulesList.length > 0
    },
  },

  methods: {
    ...mapActions([
      'deleteCursor',
      'selectMedicine',
      'switchOtherStatus',
      'updateCapsuleHourAndDate',
    ]),
    onChangeSelectValue (valueSelected, capsuleId) {
      const isOther = valueSelected === 'other'
      this.switchOtherStatus(
          {
            capsuleId,
            status: isOther,
          },
      )
      this.selectMedicine(
          {
            capsuleId,
            newMedicine: isOther ? '' : valueSelected,
          },
      )
      this.$emit('update-value', {
        value: {
          id: capsuleId,
          name: valueSelected,
        },
      })
    },
    onDeleteCursor (capsuleId) {
      this.deleteCursor(capsuleId)
      this.$emit('update-value', { value: null })
    },
    onUpdateMedicine (newValue, capsuleId) {
      this.selectMedicine(
          {
            capsuleId,
            newMedicine: newValue,
          },
      )
      this.$emit('update-value', {
        value: {
          id: capsuleId,
          name: newValue,
        },
      })
    },
    onValidDateHour () {
      const { date } = getHumanDate(1, this.today)
      const day = this.dialog.values.date.split('-')[2]

      const [hours, minutes] = this.dialog.values.hour.split(':')

      this.updateCapsuleHourAndDate({
        id: this.dialog.capsuleId,
        hour: parseInt(hours) + parseInt(minutes) / 60,
        day: date === day ? 1 : -1,
        today: this.today
      })

      this.onHiddenPickerDialog()
      this.$emit('update-value', { value: null })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "container";

.settings__container {
  max-width: 100%;

  .capsule {
    .v-card__text {
      button {
        font-size: 15px;

        @media all and (min-width: $m) {
          font-size: 18px;
        }
      }
    }
  }
}
</style>
