<template>
  <svg viewBox="0 0 27 20" width="27" height="20" x="36.5" y="10">
    <path d="M3.5,0 a10,5 0 0 0 20,0 l1,1 a11,6 0 0 1 -22,0 l1,-1"/>
    <path d="M13,6 h1 v6 h-1 v-6"/>
    <path d="M13,6 h1 v6 h-1 v-6"
          transform-origin="13.5 0"
          transform="rotate(30) translate(0 1)"
    />
    <path d="M13,6 h1 v6 h-1 v-6"
          transform-origin="13.5 0"
          transform="rotate(55) translate(0 2.5)"
    />
    <path d="M13,6 h1 v6 h-1 v-6"
          transform-origin="13.5 0"
          transform="rotate(-30) translate(0 1)"
    />
    <path d="M13,6 h1 v6 h-1 v-6"
          transform-origin="13.5 0"
          transform="rotate(-55) translate(0 2.5)"
    />
  </svg>
</template>

<script>
export default {
  name: 'CalendarSvgEyeClosed',
}
</script>

<style scoped>

</style>
