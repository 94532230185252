import { mapActions, mapGetters } from 'vuex'
import { getHumanDate } from '@/store/modules/Agenda'
import { dateSuperior } from '@/store/helpers/survey/date'

export default {
  props: {
    readOnly: {
      required: false,
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    dialog: {
      display: false,
      capsuleId: null,
      values: {
        date: null,
        hour: null,
        type: null,
        isSubAwakening: false,
      },
    },
    hourStep: null,
  }),

  computed: {
    ...mapGetters([
      'slidersList',
      'user',
    ]),
    sliderStep () {
      if (this.dialog.capsuleId) {
        if (this.diff === 0) return 0

        return (this.hourStep * 60) / (this.sliderDiff / 473)
      }

      return 0
    },
    sliderDiff () {
      if (this.dialog.capsuleId) {
        return (this.sliderEndHour.getTime() - this.sliderStartHour.getTime()) / 1000
      }
      return null
    },
    sliderEndHour () {
      if (this.dialog.capsuleId) {
        const { hh, hd } = this.sliderSelected.end
        return new Date(`${hd}T${hh}`)
      }
      return null
    },
    sliderStartHour () {
      if (this.dialog.capsuleId) {
        const { hh, hd } = this.sliderSelected.start
        return new Date(`${hd}T${hh}`)
      }
      return null
    },
    sliderId () {
      if (this.dialog.capsuleId) {
        return this.dialog.capsuleId.split('-')[0]
      }
      return ''
    },
    sliderSelected () {
      if (this.dialog.capsuleId) {
        return this.slidersList.find(slider => slider.id === this.sliderId)
      }
      return null
    },
    sliderSleepUpdated () {
      if (this.dialog.capsuleId) {
        return this.sliderSelected.sleeps.find(sleep => sleep.id === this.dialog.capsuleId)[this.dialog.values.type]
      }
      return null
    },
    today () {
      let today = null
      if (this.user && this.$route.params.day) {
        today = new Date(this.user.inclusionDate)
        today.setDate(today.getDate() + parseInt(this.$route.params.day))
      } else {
        today = new Date()
      }

      return today
    },
  },

  mounted () {
    this.hourStep = parseInt(process.env.VUE_APP_CALENDAR_STEP_TIME) || 15
  },

  methods: {
    ...mapActions([
      'sleepHourChange',
      'updateCursorSlider',
      'updateSliderHourAndDate',
    ]),
    displayDateTime ({ hd, hh }) {
      return new Date(`${hd}T${hh}`)
    },
    onDisplayPickers ({ id, hh, hd }, type = '', isSubAwakening = false) {
      this.dialog.capsuleId = id
      this.dialog.display = true
      this.dialog.values.date = hd
      this.dialog.values.hour = hh
      this.dialog.values.type = type
      this.dialog.values.isSubAwakening = isSubAwakening
    },
    onHiddenPickerDialog () {
      this.dialog.display = false
      this.dialog.capsuleId = null
      this.dialog.values.date = null
      this.dialog.values.hour = null
      this.dialog.values.type = null
      this.dialog.values.isSubAwakening = false
    },
    onValidDateHour () {
      if (this.dialog.values.date && this.dialog.values.hour) {
        const { date } = getHumanDate(1, this.today)
        const day = this.dialog.values.date.split('-')[2]

        const [hours, minutes] = this.dialog.values.hour.split(':')

        if (this.dialog.values.isSubAwakening) {
          const hourSelected = Math.floor(this.sliderSelected.start.h)
          const minutesSelected = Math.round((this.sliderSelected.start.h - hourSelected) * 60)

          let diffMinutes = parseInt(minutes) - minutesSelected
          let diffHour = parseInt(hours) - hourSelected
          diffHour = diffMinutes < 0 ? diffHour - 1 : diffHour
          diffHour = diffHour < 0 ? diffHour + 24 : diffHour
          diffMinutes = diffMinutes < 0 ? diffMinutes + 60 : diffMinutes

          // Check if the date is increase or not
          let oldValue = this.sliderSleepUpdated.h
          const sameDay = this.dialog.values.date === this.sliderSleepUpdated.hd
          const digitalValue = parseInt(hours) + (minutes / 60)

          const stepNumber = (diffHour * 60 + diffMinutes) / this.hourStep

          let isIncrease = false
          if (sameDay) {
            isIncrease = digitalValue > oldValue
          } else {
            isIncrease = dateSuperior(
              new Date(this.dialog.values.date),
              new Date(this.sliderSleepUpdated.hd),
            )
          }

          this.updateCursorSlider({
            sliderId: this.sliderId,
            sleepId: this.dialog.capsuleId,
            cursorName: this.dialog.values.type,
            field: 'stepNumber',
            value: stepNumber,
          })
          this.updateCursorSlider({
            sliderId: this.sliderId,
            sleepId: this.dialog.capsuleId,
            cursorName: this.dialog.values.type,
            field: 'x',
            value: stepNumber * this.sliderStep,
          })

          this.sleepHourChange({
            type: this.dialog.values.type,
            minutes: diffMinutes,
            hours: diffHour,
            id: this.sliderId,
            sleepId: this.dialog.capsuleId,
            today: this.today,
            isIncrease,
            checkOthers: true,
          })
        } else {
          this.updateSliderHourAndDate({
            id: this.dialog.capsuleId + '-' + this.dialog.values.type,
            hour: parseInt(hours) + parseInt(minutes) / 60,
            day: date === day ? 1 : -1,
            today: this.today,
          })
        }

        this.onHiddenPickerDialog()
        this.$emit('update-value', { value: null })
      }
    },
  },
}
