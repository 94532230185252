<template>
  <section>
    <svg :viewBox="viewBox"
         :width="canvas.width"
         :height="canvas.height"
         :class="{ 'read-only': readOnly }"
         ref="container__svg"
         id="container__svg"
         @mousemove="onMoveMouse"
         @touchmove="onMoveMouse"
         @mouseup="onDeselect"
         @touchend="onDeselect"
    >
      <path :d="mainPathBackground"
            :fill="mainPath.fill"
            :stroke="mainPath.strokeColor"
            :stroke-width="mainPath.strokeWidth"
      />

      <foreignObject :height="foreignObject.height"
                     :width="foreignObject.width"
                     :x="foreignObject.x"
                     :y="foreignObject.y"
      >
        <section v-if="cursorUpdated.hd && cursorUpdated.hh">
          <p>{{ cursorUpdated.hd | humanDate }}</p>
          <p>{{ cursorUpdated.hh }}</p>
        </section>
      </foreignObject>

      <g>
        <calendar-svg-moon :width="decoration.moon.size"
                           :height="decoration.moon.size"
                           :x="decoration.moon.x"
                           :y="decoration.moon.y"
                           :color="canvas.lineColor"
        />
        <calendar-svg-sun :width="decoration.sun.size"
                          :height="decoration.sun.size"
                          :x="decoration.sun.x"
                          :y="decoration.sun.y"
                          :color="canvas.lineColor"
        />
      </g>

      <g>
        <calendar-svg-periods :canvas="canvas" :radius="radius"/>
      </g>

      <defs>
        <linearGradient id="borderGradient" x1="0" x2="0" y1="0" y2="1">
          <stop offset="0" :stop-color="mainPath.strokeColor" stop-opacity="1"/>
          <stop offset="1" :stop-color="mainPath.strokeColor" stop-opacity="0"/>
          <stop/>
        </linearGradient>
      </defs>
      <path :d="pathGradientLeft" fill="url('#borderGradient')"/>
      <path :d="pathGradientRight" fill="url('#borderGradient')"/>

      <line :x1="canvas.thickness - (canvas.thickness / 2)"
            :x2="canvas.thickness + (radius * 6) + (canvas.thickness / 2)"
            :y1="canvas.height /2"
            :y2="canvas.height /2"
            :stroke="canvas.lineColor"
      />

      <calendar-slider-cursor :canvas="canvas"
                              :radius="radius"
                              :read-only="readOnly"
                              :slider-background="mainPathBackground"
                              :slider-background-options="mainPath"
                              :sliders-list="slidersList"
                              :step="step"
                              :step-time="stepTimeLocal"
                              :zones-selectable="zonesSelectable"
                              @cursor-selected="cursorSelected = $event"
                              @mouse-downed="mouseDowned = $event"
      />

      <calendar-capsule-cursor :canvas="canvas"
                               :radius="radius"
                               :read-only="readOnly"
                               :zones-selectable="zonesSelectable"
                               @cursor-selected="cursorSelected = $event"
                               @mouse-downed="mouseDowned = $event"
      />
    </svg>
  </section>
</template>

<script>
import CalendarCapsuleCursor from '@/components/Calendar/CapsuleCursor'
import CalendarSliderCursor from '@/components/Calendar/SliderCursor'
import CalendarSvgMoon from '@/components/Calendar/Icon/Moon'
import CalendarSvgPeriods from '@/components/Calendar/Periods'
import CalendarSvgSun from '@/components/Calendar/Icon/Sun'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'DayCalendar',

  components: {
    CalendarCapsuleCursor,
    CalendarSliderCursor,
    CalendarSvgMoon,
    CalendarSvgPeriods,
    CalendarSvgSun,
  },

  props: {
    initRadius: {
      required: false,
      type: Number,
    },
    readOnly: {
      required: false,
      type: Boolean,
      default: false,
    },
    stepTime: {
      required: false,
      type: Number,
      default: null,
    },
    today: {
      required: false,
      type: Date,
    },
  },

  data: () => ({
    canvas: {
      backgroundSize: 0,
      clicked: true,
      coordinates: {
        x: 0,
        y: 0,
      },
      element: null,
      height: 0,
      lineColor: '#777a97',
      rect: null,
      thickness: 0,
      transformOrigin: { cx: 0, cy: 0 },
      width: 0,
      zones: {
        a: { x1: 0, x2: 0, y1: 0, y2: 0 },
        b: { x1: 0, x2: 0, y1: 0, y2: 0 },
        c: { x1: 0, x2: 0, y1: 0, y2: 0 },
      },
    },
    cursorSelected: null,
    decoration: {
      moon: {
        size: 75,
        x: null,
        y: null,
      },
      sun: {
        height: null,
        width: null,
        x: '',
        y: '',
      },
    },
    foreignObject: {
      height: 0,
      width: 0,
      x: 0,
      y: 0,
    },
    mainPath: {
      fill: 'transparent',
      strokeColor: '#a9abc2',
      strokeWidth: 0,
    },
    mouseDowned: false,
    radius: null,
    step: null,
    stepTimeLocal: null,
    zoneSelected: {
      cx: 0,
      cy: 0,
      translate: '',
    },
    zonesSelectable: {
      a: {},
      b: {},
      c: {},
    },
  }),

  computed: {
    ...mapGetters([
      'capsulesList',
      'slidersList',
    ]),
    cursorUpdated () {
      if (this.cursorSelected === null) return { hh: '', hd: '' }

      let cursorUpdated = null
      if (this.cursorSelected.id && this.cursorSelected.id.split('__')[0] === 'capsule') {
        cursorUpdated = this.capsulesList.find(capsule => capsule.id === this.cursorSelected.id)
      } else {
        const [sliderId, item] = this.cursorSelected.id.split('-')
        cursorUpdated = this.slidersList.find(slider => slider.id === sliderId)[item]
      }

      return cursorUpdated || { hh: '', hd: '' }
    },
    mainPathBackground () {
      return `M${this.canvas.thickness},${this.canvas.height / 2} a${this.radius},${this.radius} 0, 0, 1 ${this.radius * 2},0 a${this.radius},${this.radius} 0, 0, 0 ${this.radius * 2},0 a${this.radius},${this.radius} 0, 0, 1 ${this.radius * 2},0`
    },
    pathGradientLeft () {
      return `M${this.canvas.thickness - (this.canvas.thickness / 2)},${this.canvas.height / 2} v${this.radius / 3} h${this.canvas.thickness} v-${this.radius / 3}z`
    },
    pathGradientRight () {
      const start = (this.canvas.thickness - (this.canvas.thickness / 2)) + (this.radius * 6)
      return `M${start},${this.canvas.height / 2} v${this.radius / 3} h${this.canvas.thickness} v-${this.radius / 3}z`
    },
    viewBox () {
      return `0 0 ${this.canvas.width} ${this.canvas.height}`
    },
  },

  created () {
    // Settings
    this.stepTimeLocal = parseInt(this.stepTime || process.env.VUE_APP_CALENDAR_STEP_TIME || 15)
    this.step = Math.PI / (12 * 60 / this.stepTimeLocal)

    const initRadiusAndSizeElements = (radius = null) => {
      this.radius = radius || this.initRadius || parseInt(process.env.VUE_APP_CALENDAR_RADIUS) || 150
      this.canvas.element = this.$refs.container__svg
      this.canvas.backgroundSize = this.radius / 5
      this.canvas.thickness = this.radius / 5
      // The sizes of the SVG
      this.canvas.width = (this.radius * 6) + (this.canvas.thickness * 3) + 50
      this.canvas.height = (this.radius * 2) + (this.canvas.thickness * 3) + 50

      this.mainPath.strokeWidth = this.canvas.backgroundSize

      // Create the different zones
      this.canvas.zones = {
        a: {
          x1: 0,
          x2: this.canvas.width / 2,
          y1: 0,
          y2: this.canvas.height / 2,
        },
        b: {
          x1: 0,
          x2: this.canvas.width,
          y1: this.canvas.height / 2,
          y2: this.canvas.height,
        },
        c: {
          x1: this.canvas.width / 2,
          x2: this.canvas.width,
          y1: 0,
          y2: this.canvas.height / 2,
        },
      }

      // Create zones selectable
      this.zonesSelectable.a = {
        cx: this.canvas.thickness + this.radius,
        cy: this.canvas.height / 2,
        translate: `translate(0)`,
      }

      this.zonesSelectable.b = {
        cx: this.canvas.thickness + (this.radius * 3),
        cy: this.canvas.height / 2,
        translate: `translate(${this.radius * 2})`,
      }

      this.zonesSelectable.c = {
        cx: this.canvas.thickness + (this.radius * 5),
        cy: this.canvas.height / 2,
        translate: `translate(${this.radius * 4})`,
      }

      // Create the origin of the cursor movement
      this.canvas.transformOrigin = {
        cx: this.canvas.thickness + this.radius,
        cy: this.canvas.height / 2,
      }

      this.decoration.moon.size = 35
      this.decoration.sun.size = 35
      this.decoration.moon.x = (this.radius * 6) + (this.canvas.thickness * 2)
      this.decoration.sun.x = (this.radius * 6) + (this.canvas.thickness * 2)
      this.decoration.moon.y = this.canvas.height / 2 + this.decoration.moon.size
      this.decoration.sun.y = this.canvas.height / 2 - (this.decoration.moon.size * 2)

      this.foreignObject.width = this.radius * 2
      this.foreignObject.height = this.radius / 2
      this.foreignObject.x = (this.radius * 2) + this.canvas.thickness
      this.foreignObject.y = this.canvas.thickness
    }

    const init = () => {
      const radius = this.initRadius || parseInt(process.env.VUE_APP_CALENDAR_RADIUS) || 150
      const canvasWidth = (radius * 6) + (radius / 5 * 3) + 50
      window.innerWidth < canvasWidth
      ? initRadiusAndSizeElements(((window.innerWidth * 90 / 100) - 50) * 5 / 33)
      : initRadiusAndSizeElements()
    }

    init()

    window.addEventListener('orientationchange', init)
    window.addEventListener('resize', init)
  },

  mounted () {
    this.canvas.element = this.$refs.container__svg
  },

  methods: {
    ...mapActions([
      'updateSliderHourAndDate',
      'updateCapsuleHourAndDate',
    ]),
    /**
     * When element is selected, calculate the coordinates of the cursor to move this element.
     * Calculates the x & y coordinates relatively to the selected zone
     * Calculates the angle relatively to this coordinates
     * Adjusts this angle with the step
     * Rotates the cursor selected relatively to the angle and to the selected zone
     */
    calculateCoordinatesCursor () {
      const x = this.canvas.coordinates.x - this.zoneSelected.cx
      const y = this.zoneSelected.cy - this.canvas.coordinates.y

      let angle = Math.sign(x) >= 0
                  ? Math.atan(y / x)
                  : Math.PI + Math.atan(y / x)

      angle = Math.round(angle / (this.step)) * this.step
      this.cursorSelected.setAttribute(
          'transform',
          `${this.zoneSelected.translate} rotate(${180 - (angle * 180 / Math.PI)}, ${this.canvas.transformOrigin.cx}, ${this.canvas.transformOrigin.cy})`,
      )

      let hour = this.hourCalculate(this.zoneSelected, angle)
      let day = this.dayCalculate(this.zoneSelected, angle)

      if (hour === 24) {
        hour = 0
        day = 1
      }

      const eventData = {
        id: this.cursorSelected.id,
        hour,
        day,
        type: '',
        today: this.today,
      }
      if (this.cursorSelected.tagName === 'path') {
        this.updateSliderHourAndDate(eventData)
      } else if (this.cursorSelected.tagName === 'g') {
        this.updateCapsuleHourAndDate(eventData)
      }
    },
    dayCalculate (zoneSelected, angle) {
      if (zoneSelected.translate === this.zonesSelectable.a.translate) {
        return -1
      } else if (zoneSelected.translate === this.zonesSelectable.c.translate) {
        return 1
      } else if (zoneSelected.translate === this.zonesSelectable.b.translate) {
        if (Math.sign(angle) > 0) {
          return -1
        } else {
          return 1
        }
      }
    },
    hourCalculate (zoneSelected, angle) {
      if (zoneSelected.translate === this.zonesSelectable.a.translate ||
          zoneSelected.translate === this.zonesSelectable.c.translate) {
        return Math.round(360 + ((Math.PI - angle) / this.step) * this.stepTimeLocal) / 60
      } else if (zoneSelected.translate === this.zonesSelectable.b.translate) {
        if (Math.sign(angle) >= 0) {
          return Math.round(1080 + ((angle - Math.PI) / this.step) * this.stepTimeLocal) / 60
        } else {
          return Math.round(((angle + (Math.PI / 2)) / this.step) * this.stepTimeLocal) / 60
        }
      }
    },
    onDeselect () {
      this.cursorSelected = null
      this.mouseDowned = false
      this.$emit('update-graph', { value: null })
    },
    onMoveMouse (event) {
      if (this.mouseDowned) {
        this.canvas.rect = this.canvas.element.getBoundingClientRect()
        this.zoneIdentification(event)
        this.calculateCoordinatesCursor()
      }
    },
    zoneIdentification (event) {
      const eventX = /touch/.test(event.type) ? event.changedTouches[0].clientX : event.clientX
      const eventY = /touch/.test(event.type) ? event.changedTouches[0].clientY : event.clientY

      this.canvas.coordinates.x = eventX - this.canvas.rect.left
      this.canvas.coordinates.y = eventY - this.canvas.rect.top

      if (this.canvas.coordinates.x > this.canvas.zones.a.x1 &&
          this.canvas.coordinates.x < this.canvas.zones.a.x2 &&
          this.canvas.coordinates.y > this.canvas.zones.a.y1 &&
          this.canvas.coordinates.y < this.canvas.zones.a.y2
      ) {
        this.zoneSelected = this.zonesSelectable.a
      } else if (this.canvas.coordinates.x > this.canvas.zones.b.x1 &&
          this.canvas.coordinates.x < this.canvas.zones.b.x2 &&
          this.canvas.coordinates.y > this.canvas.zones.b.y1 &&
          this.canvas.coordinates.y < this.canvas.zones.b.y2
      ) {
        this.zoneSelected = this.zonesSelectable.b
      } else if (this.canvas.coordinates.x > this.canvas.zones.c.x1 &&
          this.canvas.coordinates.x < this.canvas.zones.c.x2 &&
          this.canvas.coordinates.y > this.canvas.zones.c.y1 &&
          this.canvas.coordinates.y < this.canvas.zones.c.y2
      ) {
        this.zoneSelected = this.zonesSelectable.c
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "src/assets/style/style";

section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;

  svg {
    touch-action: none;

    text {
      font-size: 20px;
    }

    section {
      display: flex;
      flex-direction: column;
      align-items: center;

      p {
        margin: 0;
        font-size: 23px;
        user-select: none;
        font-weight: bold;
      }
    }
  }
}
</style>
