<template>
<svg viewBox="0 0 27 20">
  <path d="M3.5,10 a10,5 0 0 1 20,0 a10,5 0 0 1 -20,0 M4.5,10 a9,4.5 0 0 0 18,0 a9,4.5 0 0 0 -18,0"/>
  <circle r="3.5" cx="13.5" cy="10"/>
  <path d="M13,15 h1 v6 h-1 v-6"/>
  <path d="M13,15 h1 v6 h-1 v-6"
        transform-origin="13.5 10"
        transform="rotate(35) translate(0 0.5)"
  />
  <path d="M13,15 h1 v6 h-1 v-6"
        transform-origin="13.5 10"
        transform="rotate(65) translate(0 2.8)"
  />
  <path d="M13,15 h1 v6 h-1 v-6"
        transform-origin="13.5 10"
        transform="rotate(-35) translate(0 0.5)"
  />
  <path d="M13,15 h1 v6 h-1 v-6"
        transform-origin="13.5 10"
        transform="rotate(-65) translate(0 2.8)"
  />
  <path d="M13,0 h1 v5 h-1 v-6"/>
  <path d="M13,0 h1 v5 h-1 v-6"
        transform-origin="13.5 10"
        transform="rotate(-35) translate(0 -0.5)"
  />
  <path d="M13,0 h1 v5 h-1 v-6"
        transform-origin="13.5 10"
        transform="rotate(-65) translate(0 -2.8)"
  />
  <path d="M13,0 h1 v5 h-1 v-6"
        transform-origin="13.5 10"
        transform="rotate(35) translate(0 -0.5)"
  />
  <path d="M13,0 h1 v5 h-1 v-6"
        transform-origin="13.5 10"
        transform="rotate(65) translate(0 -2.8)"
  />
</svg>
</template>

<script>
export default {
  name: 'CalendarSvgEyeOpened',
}
</script>

<style scoped>

</style>
