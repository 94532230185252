<template>
  <svg viewBox="0 0 9 19">
    <path d="M6,19 h-3 v-10 h-3 l4.5,-9 l4.5,9 h-3 v10 h-3" class="arrow end"/>
  </svg>
</template>

<script>
export default {
  name: 'CalendarSvgCursorArrowEnd',
}
</script>

<style lang="scss" scoped>
@import "cursorsSlideStyle";
</style>

