<template>
  <g :class="{ 'read-only': readOnly }">
    <g v-for="slider in slidersList"
       :key="slider.id"
    >
      <path :d="sliderBackground"
            :fill="sliderBackgroundOptions.fill"
            :stroke="sliderColor(slider.type)"
            :stroke-width="sliderBackgroundOptions.strokeWidth"
            :stroke-dasharray="slider.dashArray"
            :stroke-dashoffset="slider.dashOffset"
            :id="`${slider.id}-bg`"
            style="z-index: -1;"
      />
      <path :d="cursor"
            fill="white"
            stroke="black"
            class="slider slider-cursor"
            @mousedown="onMouseDown"
            @touchstart="onMouseDown"
            :id="`${slider.id}-start`"
            :ref="`${slider.id}-start`"
      />
      <path :d="cursor"
            fill="white"
            stroke="black"
            class="slider slider-cursor"
            @mousedown="onMouseDown"
            @touchstart="onMouseDown"
            :id="`${slider.id}-end`"
            :ref="`${slider.id}-end`"
      />
    </g>
  </g>
</template>

<script>
import { getSliderColor } from '@/store/modules/Agenda/Sliders'
import { mapActions } from 'vuex'

export default {
  name: 'CalendarSliderCursor',

  props: {
    canvas: {
      required: true,
      type: Object,
    },
    radius: {
      required: true,
      type: Number,
    },
    readOnly: {
      required: true,
      type: Boolean,
      default: false,
    },
    sliderBackground: {
      required: true,
      type: String,
    },
    sliderBackgroundOptions: {
      required: true,
      type: Object,
    },
    slidersList: {
      required: true,
      type: Array,
    },
    step: {
      required: true,
      type: Number,
    },
    stepTime: {
      required: true,
      type: Number,
    },
    zonesSelectable: {
      required: true,
      type: Object,
    },
  },

  data: () => ({
    indexSelected: null,
  }),

  computed: {
    cursor () {
      const cursorHeight = this.canvas.backgroundSize + (this.radius / 10)
      const cursorWith = cursorHeight / 5
      let cursorPositionInitial = {}
      cursorPositionInitial.x = this.canvas.thickness - (cursorHeight / 2)
      cursorPositionInitial.y = this.canvas.height / 2 - (cursorWith / 2)

      return `M${cursorPositionInitial.x},${cursorPositionInitial.y} h${cursorHeight} v${cursorWith} h-${cursorHeight}z`
    },
  },

  watch: {
    slidersList: {
      deep: true,
      handler () {
        this.calculatePosition()
      },
    },
    radius () {
      this.calculatePosition()
    },
  },

  mounted () {
    this.calculatePosition()
  },

  methods: {
    ...mapActions([
      'updateArrayOffset',
    ]),
    calculatePosition () {
      this.slidersList.forEach(sliderOptions => {
        if (sliderOptions) {
          this.sliderAngleCalculate(sliderOptions.start, sliderOptions.id, 'start')
          this.sliderAngleCalculate(sliderOptions.end, sliderOptions.id, 'end')
        }
        this.calculateBackgroundSizeAndPosition(sliderOptions)
      })
    },
    sliderColor (type) {
      return getSliderColor(type)
    },
    sliderAngleCalculate (slider, id, type) {
      let zoneSelected = {
        translate: '',
        cx: null,
        cy: null,
      }
      let hour = 0
      let angle = 0
      let angleHour = 360 / 24

      if (slider.d === -1) {
        hour = 24 - slider.h

        if (hour <= 18 && hour > 6) {
          zoneSelected = this.zonesSelectable.a
          angle = (18 - hour) * angleHour
        } else if (hour <= 6 && hour >= 0) {
          zoneSelected = this.zonesSelectable.b
          angle = -((6 - hour) * angleHour)
        }
      } else if (slider.d === 1) {
        hour = slider.h

        if (hour >= 0 && hour < 6) {
          zoneSelected = this.zonesSelectable.b
          angle = 270 - (hour * angleHour)
        } else if (hour >= 6 && hour <= 18) {
          zoneSelected = this.zonesSelectable.c
          angle = (hour - 6) * angleHour
        }
      } else {
        zoneSelected = this.zonesSelectable.b
        angle = -90
      }

      this.$nextTick(() => {
        if (this.$refs[`${id}-${type}`]) {
          this.$refs[`${id}-${type}`][0].setAttribute(
              'transform',
              `${zoneSelected.translate} rotate(${angle}, ${this.canvas.transformOrigin.cx}, ${this.canvas.transformOrigin.cy})`,
          )
        }
      })
    },
    calculateBackgroundSizeAndPosition (sliderOptions) {
      if (sliderOptions) {
        const { start: cursorStart, end: cursorEnd } = sliderOptions
        let angleA = 0
        let angleB = 0
        const hourStep = 60 / this.stepTime

        if (cursorStart.d === -1 && (cursorStart.h > 6 && cursorStart.h < 18)) {
          angleA = ((cursorStart.h - 6) * this.step) * hourStep
        } else if (cursorStart.d === -1 && (cursorStart.h >= 18 && cursorStart.h <= 24)) {
          angleA = (((cursorStart.h - 18) * this.step)) * hourStep + Math.PI
        } else if (cursorStart.d === 1 && (cursorStart.h >= 0 && cursorStart.h < 6)) {
          angleA = (((cursorStart.h + 6) * this.step)) * hourStep + Math.PI
        } else if (cursorStart.d === 1 && cursorStart.h === 6) {
          angleA = 2 * Math.PI
        } else if (cursorStart.d === 1 && (cursorStart.h > 6 && cursorStart.h <= 18)) {
          angleA = (((cursorStart.h - 6) * this.step)) * hourStep + (2 * Math.PI)
        }

        if (cursorEnd.d === -1 && (cursorEnd.h > 6 && cursorEnd.h < 18)) {
          angleB = ((cursorEnd.h - 6) * this.step) * hourStep
        } else if (cursorEnd.d === -1 && (cursorEnd.h >= 18 && cursorEnd.h <= 24)) {
          angleB = (((cursorEnd.h - 18) * this.step)) * hourStep + Math.PI
        } else if (cursorEnd.d === 1 && (cursorEnd.h >= 0 && cursorEnd.h < 6)) {
          angleB = (((cursorEnd.h + 6) * this.step)) * hourStep + Math.PI
        } else if (cursorEnd.d === 1 && cursorEnd.h === 6) {
          angleB = 2 * Math.PI
        } else if (cursorEnd.d === 1 && (cursorEnd.h > 6 && cursorEnd.h <= 18)) {
          angleB = (((cursorEnd.h - 6) * this.step)) * hourStep + (2 * Math.PI)
        }

        const width = Math.abs(angleA - angleB) * this.radius
        this.updateArrayOffset({
          dashArray: `${width} ${(3 * Math.PI * this.radius) - width}`,
          dashOffset: angleA < angleB ? `-${angleA * this.radius}` : `-${angleB * this.radius}`,
          sliderId: sliderOptions.id,
        })
      }
    },
    onMouseDown (event) {
      if (!this.readOnly) {
        this.$emit('cursor-selected', null)

        const components = event.path || (event.composedPath && event.composedPath())
        if (components) {
          components.forEach(element => {
            if (element.classList && element.classList.contains('slider')) {
              this.indexSelected = element.id.split('-')[0]
              this.$emit('cursor-selected', element)
              this.$emit('mouse-downed', true)
            }
          })
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
g:not(.read-only) {
  g {
    .slider-cursor {
      cursor: pointer;
    }
  }
}
</style>
