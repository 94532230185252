<template>
  <v-card>
    <v-card-actions>
      <v-btn fab @click="onDisplayDeleteCard" :disabled="readOnly">
        <slot name="delete-icon"/>
      </v-btn>
    </v-card-actions>
    <v-card-text>
      <slot name="card-body"/>
    </v-card-text>

    <v-expand-transition>
      <v-card v-if="deleteCard.display"
              class="transition-fast-in-fast-out v-card--reveal"
      >
        <v-card-title>
          {{ $t(`calendar.container.${type}.card.deleteTitle`) }}
        </v-card-title>

        <v-card-text>
          {{ $t(`calendar.container.${type}.card.deleteText`) }}
        </v-card-text>

        <v-card-actions>
          <v-btn @click="onHiddenCard">{{ $t('general.buttons.cancel') }}</v-btn>
          <v-btn @click="onDelete">{{ $t('general.buttons.delete') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-expand-transition>
  </v-card>
</template>

<script>
export default {
  name: 'CardSliderSettings',

  props: {
    item: {
      required: true,
      type: Object,
    },
    readOnly: {
      required: false,
      type: Boolean,
      default: false,
    },
    type: {
      required: true,
      type: String,
    },
  },

  data: () => ({
    deleteCard: {
      display: false,
    },
  }),

  methods: {
    onDelete () {
      this.$emit('valid', this.item.id)
      this.deleteCard.display = false
    },
    onDisplayDeleteCard () {
      this.deleteCard.display = true
    },
    onHiddenCard () {
      this.deleteCard.display = false
    },
  },
}
</script>

<style lang="scss" scoped>
.v-card {
  .v-card__actions {
    display: flex;
    justify-content: flex-end;
  }

  > .v-card__text::v-deep {
    button:not(.v-btn--fab) {
      width: 100%;
      padding: 5px;
      font-size: 18px;
      text-align: center;

      &.v-btn {
        letter-spacing: normal;
      }
    }
  }

  .v-card--reveal {
    height: 100%;
    bottom: 0;
    opacity: 1 !important;
    position: absolute;
    width: 100%;
  }
}
</style>
