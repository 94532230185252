<template>
  <v-dialog :value="display"
            @input="$emit('update:display', $event)"
            persistent
            width="340"
            content-class="sleep-start_dialog"
            :retain-focus="false"
  >
    <v-card height="560">
      <v-card-title>
        <v-tabs fixed-tabs v-model="tabSelected">
          <v-tab key="calendar">
            <v-icon>mdi-calendar</v-icon>
          </v-tab>
          <v-tab key="time">
            <v-icon>mdi-clock</v-icon>
          </v-tab>
        </v-tabs>
      </v-card-title>

      <v-card-text>
        <v-tabs-items v-model="tabSelected">
          <v-tab-item key="calendar">
            <v-date-picker v-model="values.date" :allowed-dates="allowedDates"/>
          </v-tab-item>
          <v-tab-item key="time">
            <v-time-picker v-model="values.hour"
                           v-if="display"
                           format="24hr"
                           :allowed-minutes="allowedMinutes"
                           :min="limits.min"
                           :max="limits.max"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>

      <footer>
        <v-spacer/>
        <v-btn @click="onCancel">{{ $t('general.buttons.cancel') }}</v-btn>
        <v-btn @click="onValid">{{ $t('general.buttons.validate') }}</v-btn>
      </footer>
    </v-card>
  </v-dialog>
</template>

<script>
import { getLocaleISOString } from '@/store/modules/Agenda'
import { mapGetters } from 'vuex'

export default {
  name: 'DateHourPicker',

  props: {
    date: {
      required: true,
    },
    display: {
      required: true,
    },
    hour: {
      required: true,
    },
    isSleepPicker: {
      required: false,
      type: Boolean,
      default: false,
    },
    options: {
      required: false,
      type: Object,
      default: () => ({}),
    },
  },

  data: () => ({
    values: {
      date: null,
      hour: null,
    },
    limits: {
      max: undefined,
      min: undefined,
    },
    tabSelected: null,
  }),

  computed: {
    ...mapGetters([
      'slidersList',
      'user',
    ]),
    allowedDates () {
      return date => {
        return date === getLocaleISOString(this.yesterday) || date === getLocaleISOString(this.today)
      }
    },
    allowedMinutes () {
      return minutes => {
        const step = process.env.VUE_APP_CALENDAR_STEP_TIME || 15
        return minutes % step === 0
      }
    },
    sliderUpdated () {
      return this.options.values.isSubAwakening
             ? this.slidersList.find(slider => slider.id === this.options.capsuleId.split('-')[0])
             : this.slidersList.find(slider => slider.id === this.options.capsuleId)
    },
    subSliderUpdatedIndex () {
      return this.options.values.isSubAwakening
             ? this.sliderUpdated.sleeps.findIndex(subSlider => subSlider.id === this.options.capsuleId)
             : null
    },
    today () {
      let today = null
      if (this.user && this.$route.params.day) {
        today = new Date(this.user.inclusionDate)
        today.setDate(today.getDate() + parseInt(this.$route.params.day))
      } else {
        today = new Date()
      }

      return today
    },
    yesterday () {
      let today = new Date(this.today)
      today.setDate(today.getDate() - 1)
      return today
    },
  },

  watch: {
    date: function () {
      this.values.date = this.date
    },
    hour: function () {
      this.values.hour = this.hour
    },
    values: {
      deep: true,
      handler: function () {
        if (this.values.date === getLocaleISOString(this.today)) {
          const [hour] = this.values.hour.split(':')
          if (parseInt(hour) > 18) this.values.hour = ''
          this.limits.max = '18:00'
          this.limits.min = undefined
        } else if (this.values.date === getLocaleISOString(this.yesterday)) {
          const [hour] = this.values.hour.split(':')
          if (parseInt(hour) < 6) this.values.hour = ''
          this.limits.max = undefined
          this.limits.min = '06:00'
        }
      },
    },
  },

  mounted () {
    this.values.date = this.date
    this.values.hour = this.hour
  },

  methods: {
    onCancel () {
      this.limits.min = undefined
      this.limits.max = undefined
      this.values.date = null
      this.values.hour = null
      this.$emit('cancel')
      this.tabSelected = null
    },
    onValid () {
      if (this.tabSelected === 0) {
        this.tabSelected = 1
      } else if (this.tabSelected === 1) {
        this.$emit('update:hour', this.values.hour)
        this.$emit('update:date', this.values.date)
        this.$emit('valid')
        this.values.date = null
        this.values.hour = null
        this.limits.min = undefined
        this.limits.max = undefined
        this.tabSelected = null
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "src/assets/style/style";

.sleep-start_dialog {
  > .v-card {
    display: grid;
    grid-template-rows: 74px 420px 66px;
  }

  .v-card__title {
    background-color: transparent !important;
    padding: 0;
  }

  .v-card__text {
    margin-top: 0 !important;
    padding: 0;

    .v-picker::v-deep {
      width: 100%;
      border-radius: 0;

      .accent--text {
        color: black !important;
      }

      .v-time-picker-clock__item--disabled {
        span {
          color: inherit;
        }
      }
    }
  }

  footer {
    background-color: $whiteTransparent;
    padding: 15px;
    display: flex;
    justify-content: flex-end;

    button {
      margin-left: 15px;
    }
  }
}
</style>
