<template>
  <svg viewBox="0 0 50 50"
       :width="size"
       :height="size"
       :x="x"
       :y="y"
       :fill="color"
  >
    <path d="M24,0 h2 v5 h-2z"/>
    <path d="M24,0 h2 v5 h-2z" transform="translate(-1,0.5) rotate(40, 25, 25)"/>
    <path d="M24,0 h2 v5 h-2z" transform="translate(-3,0) rotate(80, 25, 25) "/>
    <path d="M24,0 h2 v5 h-2z" transform="translate(1,0.5) rotate(-40, 25, 25)"/>
    <path d="M24,0 h2 v5 h-2z" transform="translate(3,0) rotate(-80, 25, 25)"/>

    <path d="M18,33 v6 a2,2 0 0 0 1,2 h12 a2,2 0 0 0 1,-2 v-6 a14,14 0 1 0 -14,0z M21,31 a11,11 0 1 1 8,0 v7 h-8z"/>

    <path d="M19,43 h12 l-1,2 h-10 l-1,-2z"/>

    <path d="M21,47 h8 a6,6 0 0 1 -8,0z"/>

    <g v-if="plus">
      <circle r="10" cx="38" cy="38" fill="white"/>
      <path d="M32.5,36.5 h4 v-4 h3 v4 h4 v3 h-4 v4 h-3 v-4 h-4 v-3z"/>
    </g>
    <g v-if="minus">
      <circle r="10" cx="38" cy="38" fill="white"/>
      <path d="M32.5,37 h11 v4 h-11 v-4z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'CalendarSvgBulb',

  props: {
    color: {
      required: false,
      type: String,
      default: 'black',
    },
    minus: {
      required: false,
      type: Boolean,
      default: false,
    },
    plus: {
      required: false,
      type: Boolean,
      default: false,
    },
    size: {
      required: false,
      type: Number,
      default: 20,
    },
    x: {
      required: false,
      type: Number,
      default: 0,
    },
    y: {
      required: false,
      type: Number,
      default: 0,
    },
  },
}
</script>
