<template>
  <svg viewBox="0 0 1280.000000 1277.000000">
    <g transform="translate(0.000000,1277.000000) scale(0.100000,-0.100000)"
       :fill="color"
    >
      <path
          d="M6085 11929 c-1703 -198 -2880 -805 -3967 -2046 -140 -160 -255 -305 -327 -412 -458 -682 -739 -1453 -841 -2301 -57 -480 -52 -1038 15 -1515 187 -1326 815 -2559 1751 -3436 386 -362 512 -468 749 -626 776 -518 1742 -843 2795 -939 345 -32 869 -23 1233 21 851 103 1629 391 2302 853 451 309 956 781 1291 1207 514 652 855 1412 1024 2279 68 353 132 887 154 1306 6 109 8 112 -60 -125 -327 -1157 -1202 -2322 -2287 -3044 -651 -433 -1311 -681 -2037 -766 -1152 -134 -2374 161 -3270 790 -301 212 -621 514 -909 858 -557 668 -939 1535 -1060 2408 -38 271 -45 374 -45 664 0 368 24 620 90 960 110 565 365 1228 618 1610 678 1023 1322 1642 2057 1976 233 106 515 199 769 253 69 15 141 31 160 35 l35 9 -40 0 c-22 0 -112 -8 -200 -19z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'CalendarSvgMoon',

  props: {
    color: {
      required: false,
      type: String,
      default: 'black',
    },
  },
}
</script>
