<template>
  <svg viewBox="0 0 55 30"
       :width="width"
       :height="size"
       :x="x"
       :y="y"
       :transform="rotate"
  >
    <path d="M15,2.5 h25 a12.5,12.5 0 0 1 0,25 h-25 a12.5,12.5 0 0 1 0,-25z"
          :fill="mainColor"
          :stroke="mainColor"
          stroke-width="2"
    />
    <path d="M27.5,2.5 h12.5 a12.5,12.5 0 0 1 0,25 h-12.5 a7,12.5 0 0 0 0,-25z"
          :fill="secondaryColor"
    />
    <path d="M15,2.5 h25 a12.5,12.5 0 0 1 7.5,2.5 h-40 a12.5,12.5 0 0 1 7.5,-2.5"
          fill="rgba(255, 255, 255, 0.8)"
    />
    <g v-if="plus">
      <circle r="10" cx="45" cy="20" fill="white"/>
      <path d="M40,19 h4 v-4 h3 v4 h4 v3 h-4 v4 h-3 v-4 h-4 v-3" fill="black"/>
    </g>
    <g v-if="minus">
      <circle r="10" cx="45" cy="20" fill="white"/>
      <path d="M40,19 h11 v4 h-11 v-4" fill="black"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'CalendarSvgCapsule',

  props: {
    mainColor: {
      required: false,
      type: String,
      default: '#cd0500',
    },
    minus: {
      required: false,
      type: Boolean,
      default: false,
    },
    plus: {
      required: false,
      type: Boolean,
      default: false,
    },
    rotate: {
      required: false,
      type: String,
      default: '',
    },
    secondaryColor: {
      required: false,
      type: String,
      default: '#ffffff',
    },
    size: {
      required: false,
      type: Number,
      default: 20,
    },
    x: {
      required: false,
      type: Number,
      default: 0,
    },
    y: {
      required: false,
      type: Number,
      default: 0,
    },
  },

  computed: {
    width () {
      return 55 * this.size / 30
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
