<template>
  <svg viewBox="0 0 27 50"
       @mousedown="$emit('mousedown', $event)"
       @touchstart="$emit('mousedown', $event)"
       @mouseup="$emit('mouseup', $event)"
       @touchend="$emit('mouseup', $event)"
  >
    <calendar-svg-eye-closed width="27" height="20" x="0" y="8"/>

    <path d="M12,25 h3 v20 h-3 v-20" class="cursor start"/>
  </svg>
</template>

<script>
import CalendarSvgEyeClosed from '@/components/Calendar/Icon/EyeClosed'

export default {
  name: 'CalendarSvgCursorEyeClosed',

  components: { CalendarSvgEyeClosed },
}
</script>

<style lang="scss" scoped>
@import "cursorsSlideStyle";
</style>
